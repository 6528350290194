import { Gltf, SpotLight, useFBO, useHelper } from "@react-three/drei";
import { forwardRef, useEffect, useRef } from "react";
import * as THREE from 'three';
import { fixMaterials } from "../../helpers/Helpers";

export default forwardRef(function (props, ref) {
    const rugRef = useRef();
    const couchRef = useRef();
    const couch2Ref = useRef();

    // redl ines AAD5E7
    // D6D6C2
    // instead of 
    useEffect(() => {
        if (rugRef.current) {
            const materialsMap = {
                "Rug01": {
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    flatShading: false, color: "#D6D6C2"
                },
                "Rug02": {
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    flatShading: false, color: "#AAD5E7"
                },
                "Rug03": {
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    flatShading: false, color: "#AAD5E7"
                }
            }

            fixMaterials(rugRef.current, materialsMap);
        }
    }, [rugRef]);

    useEffect(() => {
        if (couchRef.current) {
            const materialsMap = {
                "Couch04Fabric": {
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    flatShading: false,
                    color: "#EA584C"
                },
                "Leg": {
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    flatShading: false, color: "#702b25"
                }
            }
            fixMaterials(couchRef.current, materialsMap);
        }
    }, [couchRef]);

    useEffect(() => {
        if (couch2Ref.current) {
            const materialsMap = {
                "Couch03Fabric": {
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    flatShading: false,
                    color: "#EA584C"
                },
                "Couch03Leg": {
                    flatShading: false,
                    emissive: "#f0e646",
                    emissiveIntensity: 0.05,
                    color: "#702b25"
                }
            }
            fixMaterials(couch2Ref.current, materialsMap);
        }
    }, [couch2Ref])


    return (
        <group {...props} ref={ref} >
            {/* <Sphere scale={0.1} position={[0, 0, 0]}><meshStandardMaterial color="green"></meshStandardMaterial></Sphere> */}
            <Gltf ref={couch2Ref} rotation={[0, 1.3 * Math.PI, 0]} position={[2, 0, 1]} src="/models/couch03-v1.0.gltf"></Gltf>
            <Gltf ref={couchRef} rotation={[0, Math.PI * 0.99, 0]} position={[0, 0, 2]} src="/models/couch04-v1.0.gltf"></Gltf>
            <Gltf ref={rugRef} position={[0, 0, 0]} src="/models/rug-03-v1.0.gltf" ></Gltf >
        </group >
    );

});