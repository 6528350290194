import { Box, Float, Gltf, SpotLight, Text3D, useCursor, useHelper } from "@react-three/drei";
import { forwardRef, useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import PoppinsBold from '../../facetype-fonts/Poppins_Bold.json';
import { fixMaterials } from "../../helpers/Helpers";

export default forwardRef(function (props, ref) {
    const pointLightRef = useRef();
    const deckchairRef = useRef();
    const [weiterBtnHovered, setWeiterBtnHovered] = useState();

    useCursor(weiterBtnHovered, 'pointer');

    useEffect(() => {
        if (deckchairRef.current) {
            const materialsMap = {
                "Deckchair02_Frame": {
                    emissive: "#87C7E4",
                    emissiveIntensity: 0.1,
                    flatShading: false, color: "white"
                },
                "Deckchair02_BackFrame": {
                    emissive: "#87C7E4",
                    emissiveIntensity: 0.1,
                    flatShading: false, color: "white"
                },
                "Deckchair02_Cushion": {
                    emissive: "#87C7E4",
                    emissiveIntensity: 0.1,
                    flatShading: false, color: "#D6D6C2"
                }
            }

            fixMaterials(deckchairRef.current, materialsMap);
        }
    }, [deckchairRef]);

    useHelper(pointLightRef, THREE.PointLightHelper, 'cyan');

    return (
        <group {...props} ref={ref}>
            <group rotation={[0, -0.3 * Math.PI, 0]} position={[1.3, .54, -2.2]}>
                <Box visible={false} onPointerOver={() => { setWeiterBtnHovered(true) }} onPointerLeave={() => { setWeiterBtnHovered(false) }} onClick={props.switchScenesFunc} position={[0.6, .14, 0]} scale={[1.2, .3, .1]}></Box>
                <Float floatIntensity={1} speed={6} rotationIntensity={0} floatingRange={[0, .05]}>
                    <Text3D castShadow={true} size={0.2} scale={[1, 1, .1]} font={PoppinsBold}
                    >
                        {'Weiter >'}
                        <meshPhongMaterial flatShading={true} color={weiterBtnHovered ? "#4D8FCA" : "#244E84"} />
                    </Text3D>
                </Float>
            </group>
            {/* <Sphere scale={0.1} position={[0, 0, 0]}></Sphere> */}
            <Gltf inject={<meshPhongMaterial color={new THREE.Color("#D6D6C2")} />} position={[0.1, 0, 0]} src="/models/SM_Prop_CoffeeTable_03.gltf" castShadow receiveShadow></Gltf>
            <Gltf inject={<meshPhongMaterial shininess={1} emissiveIntensity={0.05} emissive={new THREE.Color("#7fbcdb")} color={new THREE.Color("#D6D6C2")} />} position={[-1.10, 0, 0]} rotation={[0, Math.PI * 0.6, 0]} src="/models/couch-v1.0.gltf" castShadow receiveShadow></Gltf>
            <Gltf ref={deckchairRef} position={[1.5, 0, -2]} rotation={[0, .2 * Math.PI, 0]} src="/models/deckchair02-v1.0.gltf" castShadow receiveShadow></Gltf>
        </group >
    )
});