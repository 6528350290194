import { Canvas } from '@react-three/fiber';
import { Bloom, DepthOfField, EffectComposer, Noise, Vignette } from '@react-three/postprocessing'
import { Box, Backdrop, Gltf, OrbitControls, PerspectiveCamera, TransformControls, Stage, Sphere, useFBO, Text3D, Plane, ContactShadows, Environment, useHelper } from '@react-three/drei';
import * as THREE from 'three';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap, { Bounce, Power0, Power1 } from 'gsap';
import TVScene from './scenes/TVScene';
import PoppinsBold from '../facetype-fonts/Poppins_Bold.json';
import ChairsScene from './scenes/ChairsScene';
import useKeypress from '../helpers/Helpers';

export default function () {
    const [sceneTl, setSceneTl] = useState();
    const directionalLightRef = useRef();
    const cameraRef = useRef();
    const cameraLookatRef = useRef();
    const glassesRef = useRef();
    const chairsSceneRef = useRef();
    const backdropMatRef = useRef();
    const tvSceneRef = useRef();
    const backdropColor = "#87C7E4"
    const skyColor = "white";
    const groundColor = backdropColor;

    // Entrance anim
    useLayoutEffect(function () {
        let ctx = gsap.context(() => {
            if (glassesRef.current) {
                gsap.from(glassesRef.current.position, { ...glassesRef.current.position, y: glassesRef.current.position + 2 });
            }

        }); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert(); // cleanup

    }, [glassesRef]);

    const switchScenes = (playReversed = false) => {
        if (sceneTl) {
            return;
        }

        const tl = gsap.timeline({
            onComplete: () => {
            }
        });
        setSceneTl(tl);

        const newGlassesState = {
            scale: {
                x: 3,
                y: 3,
                z: 3
            },
            position: {
                x: -1.5,
                y: 1.5,
                z: -1.3,
            },
            rotation: {
                x: .45 * Math.PI,
                y: 0,
                z: 0
            }
        }

        // Animate scene out
        tl.to(glassesRef.current.position, { x: 0, y: 1.4, z: 0, duration: 1 }, "<");
        tl.to(cameraRef.current.rotation, { x: Math.PI * .01, y: 0, z: 0, duration: 1 }, "<");
        tl.to(cameraRef.current.position, { x: 0, y: 1.3, z: cameraRef.current.position.z, duration: 1 }, "<");
        tl.to(glassesRef.current.rotation, { x: .4 * Math.PI, y: Math.PI * .3, z: Math.PI * .2, duration: 1 }, "<");
        tl.to(chairsSceneRef.current.position, {
            x: -10, y: 0, z: 0, duration: 2, onComplete: () => {
                chairsSceneRef.current.visible = false;
            }
        });

        // Transition glasses to new state
        tl.to(glassesRef.current.rotation, { x: .5 * Math.PI, y: Math.PI * 0.01, z: Math.PI * 2, duration: 4, easeIn: Power0.easeIn, easeOut: Power0.easeOut }, "<");
        tl.to(backdropMatRef.current.color, { r: 240 / 255, g: 230 / 255, b: 70 / 255, duration: 2 }, "<"); // start background transition
        tl.to(glassesRef.current.scale, { ...newGlassesState.scale, duration: 2, }, "<.8");
        tl.to(glassesRef.current.position, { ...newGlassesState.position, y: 1.8, duration: 2, easeIn: Power0.easeIn, easeOut: Power0.easeOut }, "<");
        tl.to(cameraRef.current.position, { x: 1.5, y: 1.6, z: 3, easeIn: Power0.easeIn, duration: 3.6 }, "<");
        tl.to(cameraRef.current.rotation, { x: -0.244, y: 0.237, z: 0.058, easeIn: Power0.easeIn, duration: 3.6 }, "<");

        // Transition to new state
        tl.fromTo(tvSceneRef.current.position, { x: 8, y: 0, z: 0 }, { x: 0, y: 0, z: 0, easeIn: Power0.easeIn, duration: 2 }, "<1");

        glassesRef.current.traverse(function (obj) {
            if (obj.material) {
                if (obj.material.name == "GlassRMat") {
                    obj.material.transparent = true;
                    obj.material.opacity = 0;
                    obj.material.color = new THREE.Color("black");
                    tl.to(obj.material, { opacity: 1, duration: .3, }, "<");
                }
            }

            if (obj.material && obj.name.includes("Leg") || obj.name.includes("Rubber")) {
                const clonedMat = new THREE.MeshStandardMaterial().copy(obj.material);
                obj.material = clonedMat;
                obj.material.transparent = true;
                obj.material.needsUpdate = true;
                tl.to(obj.material, { opacity: 0, duration: .3, }, "<");
            }
        });

        tl.to(glassesRef.current.rotation, { x: newGlassesState.rotation.x, y: newGlassesState.rotation.y, duration: .4, ease: playReversed ? Power1.easeOut : Bounce.easeOut });
        tl.to(glassesRef.current.position, { ...newGlassesState.position, duration: .4, ease: playReversed ? Power1.easeOut : Bounce.easeOut }, "<");

        if (playReversed) {
            tl.reverse(0);
        }
    }

    useKeypress("ArrowRight", () => {
        switchScenes();
    }, [sceneTl])

    useKeypress("ArrowLeft", () => {
        window.location.reload();
    }, [sceneTl])

    return (

        <Canvas shadows={true} >
            <Environment preset="dawn"></Environment>
            <directionalLight position={[-5, 100, 100]} useRef={directionalLightRef} castShadow></directionalLight>
            <Gltf ref={glassesRef} position={[0, 0.6, 0]} rotation={[0.44 * Math.PI, -0.1 * Math.PI, 0.3 * Math.PI]} scale={0.15} src="/models/glasses-v1.9-export-ready.gltf" receiveShadow></Gltf>
            <PerspectiveCamera far={1000} ref={cameraRef} position={[0, 1.2, 2.8]} rotation={[-0.05 * Math.PI, 0, 0]} makeDefault></PerspectiveCamera>
            <hemisphereLight intensity={0.5} args={[skyColor, groundColor]} ></hemisphereLight>
            {/* <OrbitControls></OrbitControls> */}
            <Backdrop
                receiveShadow
                position={[0, 0, -10]}
                scale={[100, 20, 20]}
                floor={2} // Stretches the floor segment, 0.25 by default
                segments={20} // Mesh-resolution, 20 by default
            >
                <meshStandardMaterial ref={backdropMatRef} color={backdropColor} />
            </Backdrop>
            {/* <Sphere ref={cameraLookatRef} scale={0.05} position={[.5, .6, -1]}><meshStandardMaterial color={"red"}></meshStandardMaterial></Sphere> */}
            <EffectComposer>
                {/* <DepthOfField focusDistance={0.01} focusRange={0.02} focalLength={0.02} bokehScale={10} height={480} /> */}
                <Noise opacity={0.1} />
                <Vignette eskil={false} offset={0.3} darkness={0.7} />
            </EffectComposer>
            <TVScene visible={true} position={[10, 0, 0]} ref={tvSceneRef} ></TVScene>
            <ChairsScene visible={true} switchScenesFunc={() => { switchScenes() }} ref={chairsSceneRef}></ChairsScene>
        </Canvas>
    )
}