import { useEffect } from 'react';
import * as THREE from 'three';
export function fixMaterials(scene, materialsMap) {
    if (scene) {
        scene.traverse(function (obj) {
            if (obj.material) {
                const matSettings = materialsMap[obj.material.name];
                if (matSettings) {
                    obj.material = new THREE.MeshPhongMaterial(matSettings)
                }
            }
        })
    }
}

export default function useKeypress(key, action, dependencies) {
    useEffect(() => {
        function onKeyup(e) {
            if (e.key === key) action()
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, dependencies);
}